<template>
  <div class="mt-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CCollapse :show="!ShowDetails">
      <CRow class="m-0">
        <CCol sm="12" lg="5" class="px-0">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required"
            :label="$t('label.type')"
            :horizontal="{label: 'col-sm-12 col-lg-auto', input: 'col-sm-12 col-lg-10'}"
            :options="DocumentTypeOptions"
            v-model="$v.DocumentType.$model"
            :is-valid="hasError($v.DocumentType)"
            :invalid-feedback="errorMessage($v.DocumentType)"
            @change="AcceptedFiles($event.target.value)"
          >
            <template #append>
              <CButton 
                v-show="DocumentType"
                color="watch"
                size="sm"
                class="mr-1 rounded"
                v-c-tooltip="{
                  content: $t('label.download_instructive'),
                  placement: 'top-end',
                }"
                target="_blank"
                :href="`${$store.getters['connection/getBase']}${InstructiveRoute(DocumentType)}`"
              >
                <CIcon name="Instructive" />
              </CButton>
              <CButton 
                v-show="DocumentType"
                color="info"
                size="sm"
                class="rounded"
                v-c-tooltip="{
                  content: $t('label.download_file_structure'),
                  placement: 'top-end',
                }"
                @click="FileAlert"
              >
                <CIcon name="cil-document" />
              </CButton>
            </template>
          </CSelect>
        </CCol>
        <CCol sm="12" lg="6" class="px-0">
          <CInput
            :label="$t('label.description')"
            :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
            v-model.trim="$v.DocumentDescription.$model"
            :invalid-feedback="errorMessage($v.DocumentDescription)"
            :is-valid="hasError($v.DocumentDescription)"
            addLabelClasses="text-right required"
          />
        </CCol>
        <CCol sm="12" lg="1" class="d-flex align-items-start justify-content-end px-0">
          <CButton 
            color="add" 
            square 
            size="sm"
            :disabled="isSubmit"
            @click="Submit"
          >
            <CIcon name="checkAlt"/>
          </CButton>
        </CCol>
      </CRow>
      <vue-dropzone 
        v-if="DropZone"
        ref="myVueDropzone" 
        id="dropzone" 
        :options="dropzoneOptions" 
        :useCustomSlot="true"
        v-on:vdropzone-files-added="SendingEvent"
        v-on:vdropzone-error="DropzoneError"
        class="col-lg-12 mb-3"
      > 
        <div>
          <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
          <div>...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
        </div>
      </vue-dropzone>
      <CCollapse :show="isSubmit" style="width: 100%;">
        <CCol sm="12" class="mb-3">
          <CProgress style="width: 100%;">
            <CProgressBar
              color="success"
              variant="striped"
              animated
              :value="UploadPercent"
            >
              {{ UploadPercent != 100 ? `${UploadPercent}%` : $t('label.readingFile') }}
            </CProgressBar>
          </CProgress>
        </CCol>
      </CCollapse>
      <CCol sm="12" class="px-0">
        <dataTableExtended
          class="align-center-row-datatable"
          hover
          sorter
          :items="formatedItems"
          :fields="fields"
          :items-per-page="5"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          pagination
          column-filter
        >
          <template #Status="{ item }">
            <td class="text-center">
              <div>
                <CBadge :color="getBadge(item.Status)">
                  {{ $t("label." + item.Status) }}
                </CBadge>
              </div>
            </td>
          </template>
          <template #Options="{item}">
            <td class="center-cell">
              <CButton 
                v-if="item.FgActStPlanFile"
                color="wipe"
                size="sm"
                class="mr-1"
                @click="RemoveDocument(item)"
                v-c-tooltip="{
                  content: `${$t('label.removeDocument')}`,
                  placement: 'top-start',
                }"
              >
                <CIcon name="cil-trash"/>
              </CButton>
              <CButton
                color="excel"
                size="sm"
                class="mr-1"
                @click="DownloadArchive(item.Route)"
                v-c-tooltip="{
                content: `${$t('label.download')} ${$t('label.file')}`,
                  placement: 'top-start',
                }"
              >
              <CIcon name="cil-cloud-download"/>
              </CButton>
              <CButton
                color="watch"
                size="sm"
                class="mr-1"
                @click="(StowagePlanningFileId=item.StowagePlanningFileId, ShowDetails=true)"
                v-c-tooltip="{
                  content: $t('label.details'),
                  placement: 'top-start'
                }"
              >
              <CIcon name="cil-list-rich" />
              </CButton>
              <CButton 
                color="light-watch" 
                size="sm"
                class="mr-1"
                v-c-tooltip="{
                  content: $t('label.openErrors'),
                  placement: 'top-start'
                }" 
                @click="(FileError=item, ModalError=true)"
              >
                <CIcon name="cil-exclamation-circle" />
              </CButton>
              <CButton 
                v-if="!item.FgTransferred&&item.FgActStPlanFile&&item.Error==0&&item.Lines!=0"
                color="add"
                size="sm"
                @click="TransferFile(item)"
                v-c-tooltip="{
                  content: `${$t('label.transfer')}`,
                  placement: 'top-start',
                }"
              >
                <CIcon name="cil-share"/>
              </CButton>
              <CButton 
                v-else-if="item.FgTransferred&&item.FgActStPlanFile&&item.Error==0&&item.Lines!=0"
                color="plan"
                size="sm"
                @click="ReverseDocument(item)"
                v-c-tooltip="{
                  content: `${$t('label.reverse')}`,
                  placement: 'top-start',
                }"
              >
                <CIcon name="cil-replay"/>
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CCollapse>
    <CCollapse :show="ShowDetails">
      <FileDetails
        :active="ShowDetails"
        :StowagePlanningFileId="StowagePlanningFileId"
        @Close="(ShowDetails=false, StowagePlanningFileId='')"
      />
    </CCollapse>
    <AlertModal
      :modal="AlertModal"
      :File="FileXlsx"
      @Close="(AlertModal=false, FileXlsx={}, getStowagePlanningFile())"
    />
    <ModalError
      :modal="ModalError"
      :File="FileError"
      @Close="(ModalError=false, FileError={})"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import General from "@/_mixins/general";
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoElectronicDataValidations";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AlertModal from './alert-modal'
import FileDetails from './file-details'
import ModalError from './modal-error'

let user = JSON.parse(localStorage.getItem('user'));

//data
function data() {
  return {
    ShowDetails: false,
    DropZone: true,
    AlertModal: false,
    ModalError: false,
    Loading: false,
    isSubmit: false,
    DocumentType: '',
    DocumentTypeList: [],
    DocumentDescription: '',
    FileList: [],
    FileXlsx: {},
    FileError: {},
    StowagePlanningFileId: '',
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: '.xlsx',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${user.token}`
      }
    },
    UploadPercent: 0,
  };
}

//methods
async function getGeneralCargoTpStowageFile() {
  await this.$http.get("GeneralCargoTpStowageFile-list")
    .then((response) => {
      this.DocumentTypeList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

async function getStowagePlanningFile() {
  this.Loading = true;
  await this.$http.get("StowagePlanningFile-list", { StowagePlanningId: this.planificacionId, TpStowageFileId: '' })
    .then((response) => {
      this.FileList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function Submit() {
  try {
    this.Loading = true;
    this.isSubmit = true;
    let File = this.$refs.myVueDropzone.getAcceptedFiles()[0];
    this.$v.$touch();
    if (!File) {
      throw this.$t('label.documentQuestion');
    }else if (this.$v.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let formData = new FormData();
    formData.append('files', File);
    formData.append('StowagePlanningId', this.planificacionId);
    formData.append('TpStowageFileId', this.DocumentType);
    formData.append('FileDescription', this.DocumentDescription);

    let Route = '';
    if (this.DocumentType.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CONTAINER_LIST) {
      Route = 'GeneralCargoContainerFile';
    }else if(this.DocumentType.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BILL_OF_LADING) {
      Route = 'GeneralCargoBlsFile';
    }
    this.$http.post(Route, formData, {
      baseURL: this.$store.getters['connection/getApiBase'],
      onUploadProgress: function( progressEvent ) {
        this.UploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
      }.bind(this)
    })
    .then((response) => {
      this.$refs.myVueDropzone.removeAllFiles();
      this.DocumentDescription = '';
      let Json = response?.data?.data[0]?.Json ?? {};
      if (Json?.FgSave != 0) {
        this.FileXlsx = Json.ResponseJson[0] ?? {};
        this.AlertModal = true;
      }else {
        this.$swal.fire({
          icon: "warning",
          text: Json?.Response ? Json.Response.toUpperCase() : '',
          cancelButtonColor: '#E1373F',
          cancelButtonText: this.$t('button.cancel'),
          showConfirmButton: false,
          showCancelButton: true,

        })
      }
      this.$v.$reset();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.isSubmit = false;
      this.UploadPercent = 0;
      this.Loading = false;
    });
  } catch (error) {
    this.isSubmit = false;
    this.Loading = false;
    this.$notify({
      group: "container",
      title: "¡Error!",
      text: error,
      type: "error",
    });
  }
}

function RemoveDocument(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
    text: `${this.$t('label.deleteFile')}`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.Loading = true;
      let FileJson = {
        StowagePlanningFileId: item.StowagePlanningFileId,
      }
      this.$http.put('StowagePlanningFile-deactivate', FileJson, { root: 'FileJson'})
        .then(async (response) => {
          await this.getStowagePlanningFile();
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
        })
        .catch((err) => {
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: err,
            type: "error",
          });
        })
        .finally(() => {
          this.Loading = false;
        });
    }
  });
}

async function TransferFile(item) {
  this.Loading = true;
  let StowagePlanningFileJson = {
      StowagePlanningFileId: item.StowagePlanningFileId,
  }
  await this.$http.post("StowagePlanningBillOfLadingTransfer", StowagePlanningFileJson, { root: 'StowagePlanningFileJson'})
    .then(async() => {
      await this.getStowagePlanningFile();
      this.$notify({
        group: "container",
        title: '¡'+this.$t('label.success')+'!',
        text: this.$t('label.TransferredFile'),
        type: "success",
      });
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function ReverseDocument(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
    text: `${this.$t('label.reverseFile')}`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.Loading = true;
      let StowagePlanningFileJson = {
        StowagePlanningFileId: item.StowagePlanningFileId,
      }
      this.$http.post('StowagePlanningGeneralCargoFileTransferReverse', StowagePlanningFileJson, { root: 'StowagePlanningFileJson'})
        .then(async (response) => {
          await this.getStowagePlanningFile();
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
        })
        .catch((err) => {
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: err,
            type: "error",
          });
        })
        .finally(() => {
          this.Loading = false;
        });
    }
  });
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
  let Msg = this.DocumentType ? this.$t('label.docTypeInvalid') : this.$t('label.mustSelectDocType');
  this.$swal.fire({
    icon: 'error',
    title: '',
    text: Msg,
  })
}

function SendingEvent(file) {
  if (!this.DocumentType) {
    this.$swal.fire({
      icon: 'error',
      title: '',
      text: this.$t('label.mustSelectDocType'),
    })
    setTimeout(() => {
      this.$refs.myVueDropzone.removeAllFiles();
    }, 10);
  }
}

function InstructiveRoute(Id) {
  if (Id == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CARGO_MANIFEST) {
    return 'download/IntructivoManifiestoBl.pdf';
  } else if (Id == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CARGO_CONTAINER_LIST) {
    return 'download/InstructivoContainers.pdf';
  }else{
    return '';
  }
}

function FileAlert() {
  this.$swal.fire({
    text: `${this.$t('label.FileAlert')}`,
    icon: "warning",
    confirmButtonColor: "#42aa91",
    confirmButtonText: "OK"
  }).then((result) => {
    if (result.isConfirmed) {
      if (this.DocumentType == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CARGO_MANIFEST) {
        window.open(`${this.$store.getters['connection/getBase']}download/BillOfLadingManifest.xlsx`);
      } else if (this.DocumentType == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CARGO_CONTAINER_LIST) {
        window.open(`${this.$store.getters['connection/getBase']}download/ContainerManifest.xlsx`)
      }
    }
  })
}

function AcceptedFiles(Id) {
  this.Loading = true;
  this.DocumentType = Id ?? '';
  if (this.DocumentType) {
    let DocumentTypeData = this.DocumentTypeList.find(item => item.TpStowageFileId == this.DocumentType);
    this.dropzoneOptions.acceptedFiles = DocumentTypeData ? DocumentTypeData.Format : '.pdf, .doc, .docx, .xlsx';
  }else {
    this.dropzoneOptions.acceptedFiles = '.pdf, .doc, .docx, .xlsx';
  }
  this.$refs.myVueDropzone.removeAllFiles();
  this.DropZone = false;
  setTimeout(() => {
    this.DropZone = true;
    this.Loading = false;
  }, 10);
}

function DownloadArchive(Route) {
  return window.open( (`${this.$store.getters['connection/getBase']}${Route}`), '_blank');
}

function Reset() {
  this.$refs.myVueDropzone.removeAllFiles();
  this.ShowDetails = false;
  this.DropZone = true;
  this.DocumentType = '';
  this.DocumentDescription = '';
  this.DocumentTypeList = [];
  this.FileList = [];
  this.FileXlsx = {};
  this.FileError = {};
  this.StowagePlanningFileId = '';
  this.UploadPercent = 0;
  this.$v.$reset();
}

//computed
function DocumentTypeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  this.DocumentTypeList.map(function(e){
    chart.push({
      value: e.TpStowageFileId, 
      label: e['TpStowageFileName'+_lang] ?? '',
    })    
  })
  return chart;
}

function fields(){ 
  return [
    { key: 'Options',  label: '', _style: 'width:1%; min-width: 225px',  sorter: false,  filter: false, _classes:'text-center' },
    { key: 'Nro', label: '#',_style: 'min-width:45px;',  _classes:'text-center', filter: false},
    { key: 'TpStowageFileName', label: this.$t('label.type'), _style: 'min-width: 180px;', _classes:'text-center'},
    { key: 'FileName', label: this.$t('label.filename'), _style: 'min-width: 180px;', _classes:'text-center'},
    { key: 'FileDescription', label: this.$t('label.description'), _style: 'min-width: 250px;', _classes:'text-center'},
    { key: 'Lines', label: this.$t('label.rows'), _style: 'min-width: 130px;', _classes:'text-center'},
    { key: 'Error', label: this.$t('label.wrongRows'), _style: 'min-width: 180px;', _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'min-width: 130px;', _classes:'text-center'},
    { key: 'FormatedDate', label: this.$t('label.date'),_style: 'min-width: 130px;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'), _style: 'min-width: 130px;', _classes:'text-center'},
  ]
}

function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.FileList.map((item) => {
    return {
      ...item,
      Nro: Number.parseInt(item.Nro),
      TpStowageFileName: item['TpStowageFileName'+_lang] ?? '',
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
      _classes: !item.FgActStPlanFile ? 'table-danger' : item.Error != 0 ? 'Error-Row' : 'table-success',
    }
  })
}

export default {
  name: "electronic-data-index",
  mixins: [General],
  data,
  props:{
    active: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    AlertModal,
    FileDetails,
    ModalError,
  },
  validations: GeneralCargoBlValidations,
  methods: {
    getGeneralCargoTpStowageFile,
    getStowagePlanningFile,
    Submit,
    RemoveDocument,
    TransferFile,
    ReverseDocument,
    DropzoneError,
    SendingEvent,
    InstructiveRoute,
    FileAlert,
    AcceptedFiles,
    DownloadArchive,
    Reset,
  },
  computed: {
    DocumentTypeOptions,
    fields,
    formatedItems,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
    }),
  },
  watch: {
    active: async function (Newval) {
      if (Newval) {
        this.Loading = true;
        await this.getGeneralCargoTpStowageFile();
        await this.getStowagePlanningFile();
      }else{
        this.Reset();
      }
    }
  },
};
</script>
<style>
.swal2-header .font-size-14{
  font-size: 1.4rem !important;
}
</style>